import React, { useEffect, useRef, useState } from 'react'
import { TerminalStyles } from '../styles/TerminalStyles'
import Typewriter from 'typewriter-effect'

/* Calculate delay for next command by adding all previous ones     */
/* + 1 second for response to appear + 1.5 seconds for $ to appear  */
const calcCumulativeDelay = (function () {
  let totalDelay = 0

  return function (idx, props, typingDelay) {
    if (idx > 0) {
      totalDelay += (2 + props.command[idx - 1].length) * typingDelay + 2500
    } else {
      totalDelay = 0
    }

    return totalDelay
  }
}())

export default function Terminal (props) {
  const typingDelay = 100
  const [resp, setResp] = useState(new Array(props.command.length).fill(true))

  const responsesEndRed = useRef(null)

  const scrollToBottom = () => {
    responsesEndRed.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(scrollToBottom, [resp])

  return (
    <TerminalStyles>
      {props.command.map((command, idx) =>
        <div key={idx} className='terminal-entry'>
          <Typewriter
            options = {{
              cursor: '',
              delay: typingDelay
            }}
            onInit = {(typewriter) => {
              typewriter
                .pauseFor(calcCumulativeDelay(idx, props, typingDelay))
                .typeString('> ')
                .start()
                .pauseFor(1000)
                .typeString(command)
                .pauseFor(500)
                .callFunction(() => {
                  const respTemp = resp

                  respTemp[idx] = false
                  setResp([...respTemp])
                })
            }}
          />
          <div className={`colour-output${resp[idx] ? ' hide' : ''}`}>
            {props.response[idx]}
          </div>
        </div>
      )}
      <div ref={responsesEndRed} />
    </TerminalStyles>
  )
}
