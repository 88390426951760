import AppBorder from '../components/AppBorder'
import Layout from '../components/Layout'
import React from 'react'
import Terminal from '../components/Terminal'

export default function Home () {
  const terminalStrings = {
    command: [
      'cat welcome.txt',
      'cat skills.txt',
      'cat extras.txt'
    ],
    response: [
      `Hello. I'm Daniel Swaine. I'm an electronic engineer, developer and
      photographer. I also like to play the piano.`,
      `Electronics, Embedded C, VHDL, HTML, CSS, WordPress, Ruby on Rails,
      JavaScript, Photography and Photo Editing.`,
      `Yes, you can use the close, minimise and full screen buttons!`
    ]
  }

  return (
    <Layout>
      <AppBorder title='Terminal' colour='#073642'>
        <Terminal {...terminalStrings} />
      </AppBorder>
    </Layout>
  )
}
