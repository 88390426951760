import styled from 'styled-components'

const TerminalStyles = styled.div`
  font-family: 'Andale Mono', Consolas, 'Courier New';
  color: #839496;

  .terminal-entry {
    word-wrap: break-word;
    position: relative;
    padding: 0 1em;
    margin: 0;
  }

  .colour-output {
    color: #b58900;

    &.hide {
      display: none;
      visibility: hidden;
    }
  }

  .Typewriter {
    display: inline;
  }
`

export { TerminalStyles }
